<template>
  <!-- 费用总览 -->
  <div class="costOverview bgblue">
    <!-- 费用来源搜索部分 -->
    <div class="input">
      <el-form class="demo-form-inline" label-width="100px">
        <el-row type="flex" justify="end">
          <el-col :span="4">
            <el-form-item label="费用来源：">
              <el-select v-model="value" placeholder="全部" clearable>
                <el-option label="全部" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 图片列表部分 -->
    <div class="pic">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content flex justify-between shadow picture" @click="goToBanlace()">
            <div class="flex1 tupian">
              <img src="../../assets/images/qianbao.png" alt="" />
            </div>

            <div class="flex3">
              <p class="text">6,141,672.00</p>
              <p class="money">总金额(元)</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content flex justify-between shadow picture">
            <div class="flex1 tupian">
              <img src="../../assets/images/qianbao.png" alt="" />
            </div>
            <div class="flex3">
              <p class="text">0.00</p>
              <p class="money">待确认金额(元)</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content flex justify-between shadow picture">
            <div class="flex1 tupian">
              <img src="../../assets/images/qianbao.png" alt="" />
            </div>
            <div class="flex3">
              <p class="text">-14,470.00</p>
              <p class="money">已确认金额(元)</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content flex justify-between shadow picture">
            <div class="flex1 tupian">
              <img src="../../assets/images/qianbao.png" alt="" />
            </div>
            <div class="flex3">
              <p class="text">0.00</p>
              <p class="money">待开票金额(元)</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="6">
          <div class="grid-content flex justify-between shadow picture">
            <div class="flex1 tupian">
              <img src="../../assets/images/qianbao.png" alt="" />
            </div>

            <div class="flex3">
              <p class="text">0.00</p>
              <p class="money">已开票金额(元)</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content flex justify-between shadow picture">
            <div class="flex1 tupian">
              <img src="../../assets/images/qianbao.png" alt="" />
            </div>
            <div class="flex3">
              <p class="text">0.00</p>
              <p class="money">待支付金额(元)</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content flex justify-between shadow picture">
            <div class="flex1 tupian">
              <img src="../../assets/images/qianbao.png" alt="" />
            </div>
            <div class="flex3">
              <p class="text">6,067,4520.00</p>
              <p class="money">已支付金额(元)</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    goToBanlace(){
        this.$router.push({name:'balance'})
    }
  },
};
</script>

<style  lang="less">
//费用总览模块
//费用来源
.costOverview {
  padding: 13px;
  height: 100vh;

  .input {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 10px;
    padding-right: 10px;
    box-shadow: 0px 0px 10px #ccc;
  }
  //图片部分
  .pic {
    margin-top: 20px;
    box-sizing: border-box;
    .text {
      font-weight: 800;
    }

    .money {
      font-size: 12px;
      color: #adadad;
    }
    img {
      width: 50px;
    }
    .picture {
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      text-align: right;

      padding: 30px 40px;
      cursor: pointer;
      .tupian {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .el-form-item__label {
    font-size: 13px;
    font-weight: 800;
  }
}
</style>